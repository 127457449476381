<template>
  <div id="urls">
    <div class="api_info">
      <p><b>Client API: </b><code>{{ apiUrl }}</code></p>
    </div>
  </div>

</template>

<script>
import config from "@/config";

export default {
  name: "InfoComponent",
  cfg: config,
  apiUrl: config.apiUrl,
  testValue: config.testValue,
  setup() {
    const apiUrl = config.apiUrl
    return {
      apiUrl
    }
  }
}
</script>

<style>
.api_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  left: 3%;
  bottom: 4%;
}

.api_info > p {
  margin: 0;
}
</style>