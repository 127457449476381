<template>
  <div class="navigation">
    <nav v-if="token" class="navMenu">
      <router-link to="/" class="navLink">Добавить аккаунт</router-link>
      <router-link to="/send" class="navLink">Отправить сообщение</router-link>
    </nav>
    <form v-if="token" @submit.prevent="Logout" class="logoutForm">
      <button type="submit">Выйти</button>
    </form>
  </div>

  <router-link to="/"><img alt="LegoCar logo" src="./assets/legocar-logo.png"></router-link>

  <router-view/>
  <InfoComponent msg="info"/>
</template>

<script>
import InfoComponent from './components/InfoComponent.vue'
import axios from "axios";
import config from "@/config";
// import router from './router.js';

export default {
  name: 'App',
  created() {
    // Ваша функция, которую нужно вызвать при загрузке страницы
    this.checkToken()
  },
  components: {
    InfoComponent
  },
  mounted() {
    document.title = 'Конструктор ботов';
  },
  setup() {
    const token = localStorage.getItem("token")
    return {
      token
    }
  },
  methods: {
    Logout() {
      console.log("logout")
      localStorage.removeItem("token")
      // sessionStorage
      location.reload();
    },
    async checkToken() {
      try {
        const token = localStorage.getItem("token")
        const response = await axios.get(`${config.apiUrl}/api/v1/user/me/`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        this.options = response.data;
      } catch
          (error) {
        console.error(error);
        localStorage.removeItem("token")
      }
    },
  },
}
</script>

<style>
a {
  text-decoration: none;
}

.navigation {
  display: flex;
  gap: 16px;
  align-items: self-end;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 5%;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.navigation button, .navigation a {
  margin-top: 0;
  font-size: 13.3px;
  min-height: 25.5px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.logoutForm {

}

.navMenu {
  display: flex;
  gap: 16px;
}

.navLink {
  background-color: #41b883;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
