<template>
  <div id="app">
    <h1>Авторизация:</h1>
    <form v-if="!isFormSubmitted" @submit.prevent="submitLoginForm" class="form">
      <div class="form_content">
        <div>
          <label id="formLabel"><b>Логин: </b></label>
          <label id="formLabel"><b>Пароль: </b></label>
          <label id="formLabel"><b>Запомнить</b></label>
        </div>
        <div>
          <input id="formInput" type="text" v-model="username"/>
          <input id="formInput" type="password" v-model="password"/>
          <input id="formInput" type="checkbox" v-model="remember"/>
        </div>
      </div>
      <div>
        <button type="submit">Войти</button>
        <p>
          <b style="color: red">{{login_error}}</b>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
// import router from '../router.js';

export default {
  name: "LoginPage",
  data() {
    return {
      password: "",
      username: "",
      remember: false,
      isFormSubmitted: false,
      login_error: ""
    };
  },
  methods: {
    submitLoginForm() {
      const data = {
        password: this.password,
        username: this.username,
        remember: this.remember,
        isFormSubmitted: false,
      };

      axios
          .post(`${config.apiUrl}/api/v1/user/login/`, data)
          .then((response) => {
            this.isFormSubmitted = true;
            this.responseData = response.data;
            const token = response.data.access_token;
            localStorage.setItem('token', token);
            axios.defaults.headers.common['Authorization'] = token;
            location.reload();
            if (this.remember){
              console.log("hello world!")
            }
          })
          .catch((error) => {
            console.log(error)
            localStorage.removeItem('token')
            this.login_error = "Неверный логин или пароль"
          });
    }
  },
}
</script>

<style>

</style>