<template>
  <div class="container">
    <div class="form-container">
      <label>Сессия:</label>
      <select v-model="selectedOption" @change="updateApiData">
        <option v-for="option in options" :key="option.id" :value="option.id">
          {{ option.title }}
        </option>
      </select>

      <label>API ID:</label>
      <input type="text" v-model="apiId" disabled>

      <label>API Hash:</label>
      <input type="text" v-model="apiHash" disabled>

      <label>Сообщение:</label>
      <textarea v-model="message"></textarea>

      <label>Пользователи:</label>
      <textarea v-model="users"></textarea>

      <button @click="sendMessage">Отправить сообщение</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from "@/config";

export default {
  data() {
    return {
      selectedOption: null,
      options: [],
      apiId: '',
      apiHash: '',
      message: '',
      users: '',
    };
  },
  async created() {
    try {
      const token = localStorage.getItem("token")
      const response = await axios.get(`${config.apiUrl}/api/v1/account/session/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      this.options = response.data;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    updateApiData() {
      const selectedOption = this.options.find(option => option.id === this.selectedOption);
      this.apiId = selectedOption.api_id;
      this.apiHash = selectedOption.api_hash;
    },
    async sendMessage() {
      try {
        const token = localStorage.getItem("token")
        const userList = this.users.split(',');

        const selectedOption = this.options.find(option => option.id === this.selectedOption);

        await axios.post(`${config.apiUrl}/api/v1/account/spam/`, {
          api_id: this.apiId,
          api_hash: this.apiHash,
          text: this.message,
          phone_number: selectedOption.title,
          user_list: userList,
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        console.log('Сообщение отправлено');
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 25px;
}

label {
  margin-top: 10px;
}

input,
textarea,
select {
  width: 300px;
  margin-top: 5px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #41b883;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #35987b;
}

</style>