<template>
  <div id="app">
    <h1>Добавить аккаунт:</h1>
    <form v-if="!isFormSubmitted" @submit.prevent="submitFirstForm" class="form">
      <div class="form_content">
        <div>
          <label id="formLabel"><b>Номер телефона:</b></label>
          <label id="formLabel"><b>API ID:</b></label>
          <label id="formLabel"><b>API Hash:</b></label>
        </div>
        <div>
          <input id="formInput" type="text" v-model="phoneNumber"/>
          <input id="formInput" type="text" v-model="apiId"/>
          <input id="formInput" type="text" v-model="apiHash"/>
        </div>
      </div>
      <div>
        <button type="submit">Создать</button>
      </div>
    </form>

    <div v-if="responseData">
      <h2>Введите код:</h2>
      <form @submit.prevent="submitSecondForm">
        <div class="form_content">
          <div>
            <label><b>Номер телефона:</b></label>
            <label><b>Код:</b></label>
          </div>
          <div>
            <input type="text" v-model="phoneNumber" disabled/>
            <input type="text" v-model="code"/>
          </div>
        </div>
        <div>
          <button type="submit">Отправить</button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

export default {
  data() {
    return {
      phoneNumber: "6281337560747",
      apiId: "1310128",
      apiHash: "91afc0d1a4fcfef925d6a407af04b345",
      code: "",
      responseData: null,
    };
  },
  methods: {
    submitFirstForm() {

      const token = localStorage.getItem("token")
      const data = {
        phone_number: this.phoneNumber,
        api_id: this.apiId,
        api_hash: this.apiHash,
        isFormSubmitted: false,
      };

      axios
          .post(`${config.apiUrl}/api/v1/account/create/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
          .then((response) => {
            console.log(response.data);
            this.isFormSubmitted = true;
            this.responseData = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
    },
    async submitSecondForm() {
      const token = localStorage.getItem("token")
      const data = {
        phone_number: this.phoneNumber,
        code: this.code,
      };
      console.log(`pizda ${token}`)


      await axios.post(`${config.apiUrl}/api/v1/account/login/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
          .then((response) => {
            console.log(response.data);
            location.reload();
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#formLabel {

}

#formInput {

}

form {

}

.form_content {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
}

.form_content > div {
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

input {
  border-radius: 10px;
  padding: 4px 6px;
  border: 1px solid #c3bcbc;
}

button {
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

button:hover {
  box-shadow: 0 1px 8px #c3bcbc;
}
</style>