import {createRouter, createWebHistory} from 'vue-router';
import LoginPage from './components/LoginPage.vue';
import CreateAccount from './components/CreateAccount.vue';
import sendMessage from "@/components/sendMessage";

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta: {
            requiresGuest: true
        }
    },
    {
        path: '/send',
        name: 'SendMessage',
        component: sendMessage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/',
        name: 'CreateAccount',
        component: CreateAccount,
        meta: {
            requiresAuth: true
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem("token")) {
            next()
            return
        }
        next('/login')
    } else {

        if (to.meta.requiresGuest && localStorage.getItem("token")) {
            next('/');
        } else {
            next();
        }
    }
})

export default router;
